import TitleBlock from './TitleBlock';
import './App.css';
import Post from './Post';
import { useEffect, useState } from 'react';

function App() {

  const [posts, setPosts] = useState([]);
  const [postsContent, setPostsContent] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('/posts.json')
      .then(response => response.json())
      .then(data => setPosts(data))
      .then(() => setLoading(false));
  }, []);

  const oldPosts = [];
  for (let post of posts.slice(1)) {
    oldPosts.push(<Post post={post}/>);
  }

  return (
    <div className="App">
      <div className="App-header">
        (K/Z).(S/L).
      </div>
      <div className='App-Title-Page'>
        <div className='App-Title-Page-L'>
          <TitleBlock />
        </div>
        <div className='App-Title-Page-R'>
          {loading ?
            (<h3>Loading...</h3>) :
              <div>
              <h4> Latest Post:</h4>
              <Post post={posts[0]}/>
              </div>
          }
        </div>
        <div className='App-Title-Page-Bottomtext'>
          <h3>{oldPosts.length > 0 ? "Scroll for more..." : ""}</h3>
        </div>
      </div>
      {
        oldPosts
      }
    </div>
  );
}

export default App;
