import Markdown from "react-markdown";
import { useEffect, useState } from "react";

import "./Post.css"

const Post = (props) => {
    const [loading, setLoading] = useState(true);
    const [body, setBody] = useState("");

    useEffect(() => {
        fetch(`${props.post.url}`)
            .then(response => response.text())
            .then(data => setBody(data))
            .then(() => setLoading(false));
    }, [props.post]);

    return (
        <div className="Post">
            <h1>{props.post.title}</h1>
            <hr/>
            {loading ? <h3>Loading...</h3> :
            <Markdown>{body}</Markdown>}
        </div>
    );
}

export default Post;