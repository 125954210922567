import React, { useState } from 'react';
import './TitleBlock.css'

const taglines = [
    "Cache Rules Everything Around Me",
    "*Fun Tagline*",
    "As Seen On Your Terminal!",
    "P!=NP",
    "I'm a tagline!",
    "Lorem Ipsum Dolor Sit Amet",
    "Taglines Are Hard",
    "Taglines Are Easy",
    "Bowties Are Cool",
    "Why Are We Here?",
    "F*ck It, Ship It",
    "She'll Be Right",
    "You're Doing Great",
    "Ur Mom",
    "Tell your cat I said pspspspspsps",
    "It's Dangerous To Go Alone, Take This!",
    "Sudo Make Me A Sandwich",
    "I'm Not A Robot",
    "I'm Not A Cat",
    "I'm A Banana!",
    "I'm A Potato!",
    "CPU Fan Go Brrrr",
    "It's Wednesday My Dudes",
    "As seen on your treadmill!",
    "Beep Beep Beep",
    "Abazaba, You're My Only Friend",
    "Tag, You're It!",
    "Blame The Intern",
    "Blahaj, You're My Only Friend",
    "You're Not A Fish",
    "FBI Open Up!",
    "Relevant XKCD",
    "Popcorn!? Already!?",
    "14K of Fiberglass And Human Suffering",
    "640k Ought To Be Enough For Anyone",
    "God F*cking Dammit",
    "Once More, With Passion",
    "WYSIWYG",
    "Anotha One",
    "Another Shitty Engineering Blog",
    "Modern Problems Require Modern Solutions",
    "418: I'm A Teapot",
    "404: Tagline Not Found",
    "1337 H4X0R",
    "Bruh Moment",
    "C'est La Vie",
    "Taco Tuesday",
    "It's Not A Bug, It's A Feature",
    "F*ck It, We'll Do It Live",
    "rm -rf / --no-preserve-root",
    "[ $[ $RANDOM % 6] = 0 ] && rm -rf / || echo \"Click\"",
    ":(){ :|:& };:",
    "git push --force",
    "npm install life-meaning",
    "while(alive){coffee++}",
    "200 OK (Trust Me Bro)",
    "Environment: Production't",
    "Works On My Machine ¯\\_(ツ)_/¯",
    "Did You Try Turning It Off And On Again?",
    "Tabs > Spaces",
    "Spaces > Tabs",
    "Emacs > Vim",
    "Vim > Emacs",
    "SVN > Git",
    "A Series Of Tubes",
    "var life = undefined",
    "Docker Is Just Fancy ZIP Files",
    "Your Code Is Bad And You Should Feel Bad",
    "import meaning from 'life'",
    "HTTP/418 I'm A Teapot",
    "Powered By Stack Overflow",
    "Time Is An Illusion. Lunchtime Doubly So.",
    "chmod -R 777 /",
    "null !== undefined // true story",
    "!false === true",
    "Reality.exe Has Stopped Working",
    "git blame someone-else",
    "console.log('debugging')",
    "Arrays Start At 1",
    "vim: The Exit Game",
    "Runs On My Neural Network",
    "JSON.parse(life)",
    "typeof NaN === 'number'",
    "There Is No Cloud, Just Other People's Computers",
    "Schrödinger's Deployment",
    "Your Password Must Contain A Unicorn",
    "Copy Pasta From Stack Overflow",
    "#!/bin/sh*t",
    "sudo apt-get install girlfriend",
    "ERROR: Success",
    "Made With </love>",
    "Ctrl+C Ctrl+V Engineering",
    "AI Wrote This (Probably)",
    "Touch Grass Exception",
    "Technical Debt Collector",
    "Rubber Duck Approved",
    "My Disappointment Is Immeasurable And My Day Is Ruined",
    "I'm Not A Robot, I'm A Unicorn",
    "Lead Based",
    "Based and Unixpilled",
    "Did You See That Ludicrous Display Last Night?",
    "Is It Definitely Plugged In?",
    "Hack The Planet!",
    "Beep Boop",
    "Meow",
    "We're Gonna Need A Bigger Boat",
    "B*tch, I'm A Cow",
    "You Wouldn't Download A Car",
    "You Can Fit A Whole Lotta Bytes In This Bad Boy",
    "100% AI Slop",
    "Powered By Coffee"
];

const randomIndex = () => {
    return Math.floor(Math.random() * taglines.length);
}

const TitleBlock = () => {
    const [taglineIndex, setTaglineIndex] = useState(randomIndex());
  return (
    <div className='Title-Block'>
      <div className='Title'>np24</div>
      <div className='Divider'/>
      <div className='Tagline' onMouseDown={()=>{setTaglineIndex(randomIndex())}}>{taglines[taglineIndex]}</div>
    </div>
  );
}

export default TitleBlock;